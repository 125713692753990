.user_nav {
    margin-bottom: 0px;
}

.user_nav .nav {
    display: block !important;
}

.user_nav ul {
    padding: 0 5px;
    border: 0px !important;
}

.user_nav ul li {
    margin-bottom: 5px !important;
}

.user_nav ul li:last-child {
    margin-bottom: 0px;
}

.user_nav ul li a {
    padding: 5px 15px 5px 25px;
    display: block;
    border: 1px solid transparent;
    border-radius: 5px;
}

.user_nav ul li a:hover,
.user_nav ul li a.active {
    border: 1px solid #047475 !important;
    color: #047475 !important;
    font-weight: bold;
    background-color: #E8E8E8 !important;
}

.user_nav ul li a img {
    margin-right: 15px;
    width: 40px;
}

.doc-sec {
    border-bottom: 2px dotted #127a7d;
    align-items: center;
    padding: 5px 0px;
}

.doc-name {
    display: flex;
    vertical-align: baseline;
    align-items: center;
}

.doc-name h1 {
    font-size: 15px;
}

.doc-icon {
    display: inline-flex;
    float: right;
}

.doc-icon input {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 47px;
}

.doc-icon_ {
    display: inline-flex;
    float: right;
}

.doc-icon_ .after__ {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 47px;
}

.f-update {
    display: inline-block;
    /* background-image: url("../img/icons/update-icon.svg"); */
    background-repeat: no-repeat;
    color: transparent;
    cursor: pointer;
    height: 50px;
    width: 100%;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.f-view {
    display: inline-block;
    /* background-image: url("../img/icons/eye-icon.svg"); */
    background-repeat: no-repeat;
    color: transparent;
    cursor: pointer;
    height: 50px;
    width: 100%;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.f-delete {
    display: inline-block;
    /* background-image: url("../img/icons/delete-icon.svg"); */
    background-repeat: no-repeat;
    color: transparent;
    cursor: pointer;
    height: 50px;
    width: 100%;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.f-upload {
    display: inline-block;
    /* background-image: url("../img/icons/upload-icon.svg"); */
    background-repeat: no-repeat;
    color: transparent;
    cursor: pointer;
    height: 50px;
    width: 100%;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.doc-pd {
    padding-top: 10px !important;
}

.appli-box-t {
    padding: 12px 12px 12px 12px;
}

.appli-box img {
    width: 100%;
}

.appli-box-d {
    padding-right: 30px;
}

.course-d {
    position: absolute;
    top: 7%;
    left: 30%;
    width: 100%;
}

.cam input {
    opacity: 0;
    position: absolute;
    left: -4px;
    top: -11px;
    width: 23px
}

.appli-box .steps li.done {
    color: #3a8983 !important;
}

.appli-box .steps li.done:before {
    background-color: #3a8983;
    border-color: #3a8983;
    color: white;
    content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    line-height: 30px;
}

.appli-box .steps li:before {
    content: "\f111";
    font: normal normal normal 22px/1 FontAwesome;
    position: relative;
    background-color: #9c9c9c;
    color: #7a7a7a;
    width: 29px;
    height: 29px;
    line-height: 29px;
    margin: 0 auto 0.5em;
    border-radius: 100%;
    font-size: 22px;
    text-align: center;
    display: block;
    z-index: 2;
}

.appli-box .steps ul {
    margin-left: 5px;
}

.track-appli {
    text-align: right;
    font-weight: 600 !important;
    color: #3a8983;
    cursor: pointer;
    padding-bottom: 10px;
}

.appli-box .steps li {
    float: left;
    display: inline-block;
    position: relative;
    counter-increment: li;
    width: 9.5%;
    text-align: center;
    color: #9d9d9d;
    transition: all 0.5s ease;
    font-size: 11px;
}

.pd-0 {
    padding: 0px;
}

.appli-st strong {
    font-weight: 700;
    color: #116770;
    font-size: 16px;
}

input[type=file] {
    cursor: pointer;
    margin-top: 10px;
    border-color: transparent !important;
}

.icon__ {
    padding-right: 12px;
}

.appli-up {
    text-align: right;
    color: grey;
    font-size: 13px;
    position: absolute;
    right: 4%;
    bottom: 20px;
}

.appli-up p {
    font-size: 14px !important;
}

.appli-st {
    text-align: right;
}

.appli-t {
    text-align: center;
    margin-bottom: 20px;
}

.appli-t h1 {
    font-size: 18px;
    color: #fbb415;
    font-weight: 600;
    padding-bottom: 8px;
}

.appoint {
    background-color: #fff;
    box-shadow: 0px 0px 10px #ddd;
    border-radius: 5px;
    width: 90%;
    margin: auto;
}

.apply_btn {
    position: absolute;
    right: 63px;
}

.appoint img {
    width: -webkit-fill-available;
    height: auto;
    border-bottom-right-radius: 10px;
}

.appoint h1 {
    font-size: 15px;
    font-weight: 600;
}

.appoint h2 {
    font-size: 13px;
    line-height: 25px;
}

.c-d-gree {
    color: #408e8f;
    font-size: 13px !important;
}

.text_hyper {
    text-decoration: underline !important;
    color: #047475 !important;
}

.other_info_p {
    overflow: hidden;
}

.other_info:before {
    background-color: #fbb415;
    transform: skewY(-317deg);
    content: '';
    position: absolute;
    width: 36px;
    height: 80px;
    top: -65px;
    right: 15px;
    border-radius: 0;
}

.other_info {
    padding-top: 10px;
}

.degi-1 {
    color: #c1bebe;
    font-weight: 600;
}

.couns-d {
    padding: 10px;
}

.user_nav ul li a {
    font-weight: 600;
}

.op-l {
    font-size: 13px !important;
}

.share-item {
    margin: 0px 5px;
}

.share-earn .steps li.active {
    font-weight: bold;
    color: #3a8983 !important;
    width: 33%;
}

.steps li.refer-status:before {
    margin-bottom: 15px;
}

.steps li.refer-status img {
    margin-top: 10px;
}

.share_1 {
    text-align: center;
}

.share_1 p {
    font-weight: 600
}

.share-c {
    background-color: #ddd;
    width: 18%;
    margin: auto;
    font-weight: 700;
    border: 1px solid #b1afaf;
    box-shadow: 0px 0px 0px 1px #ddd;
    text-align: center;
    cursor: pointer;
    margin: 10px auto 3px auto;
}

#copied {
    margin-top: 5px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
}

.sharing-t {
    margin-top: 5px;
}

.sharing-t a {
    color: #3a8983;
    font-weight: 600;
}

.social .fbtn {
    width: 30px;
    height: 30px;
    color: #126f76;
    border-radius: 100%;
    background-color: transparent;
    border: 1px solid;
    margin: 0px 5px;
    align-items: center;
    display: flex;
}

.social .fa {
    margin: auto;
    font-size: 15px;
}

.social .fbtn:hover {
    background-color: #126f76;
    color: #fff;
}

.share-button.sharer {
    height: 20px;
    display: inline-block;
    text-align: center;
    margin: auto;
    width: 100%;
    margin-top: 65px;
}

.share-button.sharer .social.active.top {
    transform: scale(1) translateY(-10px);
}

.share-button.sharer .social.active {
    opacity: 1;
    transition: all 0.4s ease 0s;
    visibility: visible;
}

.share-button.sharer .social.networks-5 {
    display: flex;
    justify-content: center;
}

.share-button.sharer .social.top {
    margin-top: -85px;
    transform-origin: 0 0 0;
}

.share-button.sharer .social {
    opacity: 0;
    transition: all 0.4s ease 0s;
    visibility: hidden;
}

.share-img {
    margin-left: -10px;
    padding-right: 10px;
}

.earned-t table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.earned-t-tr-a {
    border-top: 1px solid #3a8983;
}

.earned-t-tr-b {
    background-color: #3a8983;
    margin: 0px;
    border: 2px solid #3a8983;
}

.earned-t-tr-b td {
    color: #fff !important;
}

.earned-t td {
    border-bottom: 1px dotted #3a8983;
    border-left: 1px solid;
    text-align: left;
    border-right: 1px solid;
    padding: 8px;
    color: #3a8983;
    font-weight: 600;
}

.new-head01 {
    padding: 10px 10px;
    width: 20%;
}

.new-head01 h5 {
    color: #047475;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 8px;
    font-size: 13px;
}

.d-btn {
    padding: 7px 20px;
    font-size: 14px;
}

.new-head01 p {
    color: #555;
    font-size: 13px !important;
    line-height: 20px !important;
}

.first-con1 {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    border-radius: 4px;
    padding: 10px 2px 10px 2px;
}

.short-list-box {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    border-radius: 4px;
    display: flex;
    position: relative;
}

.short-list-uni {
    width: 100%;
    max-width: 155px;
    height: 155px;
    text-align: center;
    align-items: center;
    display: flex;
    margin-left: 7px;
}

.short-list-uni img {
    width: 100%;
    border-radius: 5px 0px 0px 5px;
    max-height: 145px;
    margin: 0 auto;
}

.review-rating ul li {
    display: inline-block;
}

.border-1-grey {
    border: 1px solid #ddd;
}

.fields-wrp form .fieldbtn input[type="button"] {
    color: #fff;
    border: none;
    padding: 10px 25px;
    transition: all 0.3s ease;
    border-radius: 5px;
}

.coach-con-profile img {
    width: 100%;
    margin: 0 auto;
}

.review_img {
    width: 250px;
}

.service-heading {
    font-size: 20px;
    line-height: 10px;
}

.coach-con-profile {
    text-align: center;
    align-items: center;
    max-height: 200px;
    overflow: hidden;
    /* display: flex; */
}

.shortlist-about {
    padding: 15px;
}

.shortlist-about h1 {
    font-size: 20px;
    color: #187273;
    font-weight: 600;
}

.shortlist-appli-btn {
    border: 3px solid #fcbd30;
    color: #fcbd30;
    font-size: 14px;
    padding: 10px 0px;
    width: 160px;
    display: inline-block;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0px 10px;
}

.shortlist-appli-btn:hover {
    border: 3px solid #fcbd30;
    background-color: #fcbd30;
    color: #fff;
}

.appli-btn {
    position: absolute;
    right: 15px;
    top: 85px;
    display: flex;
}

.counsellor-btn {
    margin-top: 15px;
}

.get-counsellor-btn {
    border: 0px;
    background: rgb(14, 40, 70, 1);
    background: -moz-linear-gradient(180deg, rgba(14, 40, 70, 1) 0%, rgba(18, 123, 126, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(14, 40, 70, 1) 0%, rgba(18, 123, 126, 1) 100%);
    background: linear-gradient(180deg, rgba(14, 40, 70, 1) 0%, rgba(18, 123, 126, 1) 100%);
    color: #fff;
    padding: 15px 34px;
    width: 100%;
    border-radius: 5px;
}

.get-counsellor-btn:hover {
    color: #fff;
}

.close-1 {
    cursor: pointer;
    position: absolute;
    top: 10%;
    right: 0;
    padding: 12px 16px;
    transform: translate(0%, -50%);
    font-size: 28px;
    font-weight: 600;
    color: #12797d;
    z-index: 9;
}

.c-time {
    font-size: 13px;
    color: #9d9d9d;
    line-height: 15px;
}

.shortlist-close {
    position: relative;
    margin-bottom: 25px;
}

.couns-disc {
    font-size: 13px !important;
    line-height: 20px !important;
    padding-bottom: 5px;
}

.coun-btn {
    text-align: center;
    width: 100%;
    margin: 15px auto;
}

.couns-chat {
    transition: all 0.3s ease 0s;
    background: #047475;
    color: #fff;
    font-weight: 500;
    padding: 10px 3px;
    border-radius: 5px;
    font-size: 12px;
    height: 38px;
    margin-left: 0;
    margin-bottom: 10px;
}

.couns-chat:hover {
    color: #fff;
}

.couns-book {
    background: #fcbd30;
    color: #000;
    font-weight: 500;
    margin-left: 0;
    margin-right: 0;
}

.couns-book:hover {
    color: #000;
}

.couns-chat img {
    width: auto;
    padding-right: 10px;
}

video::-webkit-media-controls-panel {}

video::-webkit-media-controls-play-button {
    background-color: #047475;
    border-radius: 50px;
    padding: 10px;
}

.video-sec {
    background-color: #fff;
    box-shadow: 0px 0px 10px #ddd;
    border-radius: 5px;
    width: 100%;
    margin: auto;
    text-align: left;
    padding-bottom: 20px;
}

.video-thumb {
    text-align: left;
    line-height: 20px !important;
    font-size: 13px !important;
    padding: 10px;
    font-weight: 600 !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 70px;
}

.video-d {
    padding: 10px;
    color: #000;
}

.profile_field.fields-wrp form .field_gender h6 input {
    margin-left: 10px;
    vertical-align: middle;
}

.revie-uni h1 {
    font-size: 24px;
    color: #047475;
    font-weight: 600;
    padding: 13px 20px;
}

.exampleFormControlSelect02 {
    font-size: 14px;
    font-weight: 600;
}

.content_of_read_more_btn {
    display: none;
}

#discussionForm {
    display: none;
}

.ReadMoreBtn {
    cursor: pointer;
    float: right;
}

.revie-p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 22px;
    font-size: 14px;
    padding: 2px 20px 3px 20px;
}

.revie-p p {
    line-height: 22px !important;
}

.star-rating01 li {
    justify-content: right;
    font-size: 27px;
    color: #fcbd30;
}

.star-rating01 li {
    list-style: none;
}

.social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-top: -10px;
    padding-right: 20px;
}

.social-buttons__button {
    margin: 20px 6px 12px;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 30px;
    height: 30px;
    text-decoration: none;
    border-radius: 100%;
    background: #fff;
    text-align: center;
}

.social-button::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
}

.social-button:focus,
.social-button:hover {
    color: #fff;
}

.social-button:focus::after,
.social-button:hover::after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
}

.social-button i,
.social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.social-button i {
    font-size: 22px;
}

.social-button svg {
    height: 40%;
    width: 40%;
}

.social-button--google01 {
    color: #b75655;
}

.social-button--google01::after {
    background: #b75655;
}

.social-button--facebook01 {
    color: #3a5999;
}

.social-button--facebook01::after {
    background: #3a5999;
}

.social-button--instagram01 {
    color: #3e719a;
}

.social-button--instagram01::after {
    background: #3e719a;
}

.social-button--linkedin01 {
    color: #0b66c3;
}

.social-button--linkedin01::after {
    background: #0b66c3;
}

img.w-10 {
    height: auto;
}

.edit-interest:hover {
    color: #fcbd30;
}

.edit-interest {
    font-size: 25px;
    cursor: pointer;
    transition: .5s;
}

.form-control-sm {
    padding: 0.25rem 0.5rem !important;
}

select[name=IntakeInput],
select[name=SpecializationInput],
select[name=ObjectiveInput],
select[name=ExamName],
select[name=QualificationInput],
select[name=QualificationResultType],
select[name=CourseLevel],
select[name=CourseName],
select[name=CountryInput] {
    text-transform: lowercase;
}

.appoint-coach-con img {
    border-bottom-right-radius: 0;
}

._appointment_img img {
    max-height: 168px;
}


/* mobile breakpoint start */

@media screen and (max-width: 767px) {
    /* nav-toggler css start */
    .h_50 {
        height: 40px;
    }
    .nav-toggler {
        border: 3px solid #116d75;
        padding: 5px;
        background-color: #116d75;
        cursor: pointer;
        height: 39px;
        display: none;
    }
    .nav-toggler span,
    .nav-toggler span:before,
    .nav-toggler span:after {
        width: 28px;
        height: 3px;
        background-color: #fff;
        display: block;
        transition: .3s;
    }
    .nav-toggler span:before {
        content: '';
        transform: translateY(-9px);
    }
    .nav-toggler span:after {
        content: '';
        transform: translateY(6px);
    }
    .nav-toggler.toggler-open span {
        background-color: transparent;
    }
    .nav-toggler.toggler-open span:before {
        transform: translateY(0px) rotate(45deg);
    }
    .nav-toggler.toggler-open span:after {
        transform: translateY(-3px) rotate(-45deg);
    }
    .container-1 {
        max-width: 720px;
    }
    /* navbar css for mobile start */
    .nav-toggler {
        display: block;
        position: absolute;
        z-index: 99;
    }
    .site-navbar {
        min-height: 60px;
    }
    .site-navbar ul {
        position: absolute;
        width: 65%;
        height: calc(100vh - 60px);
        left: 0;
        top: 60px;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid #444;
        background-color: rgb(255 255 255);
        max-height: 0;
        overflow: hidden;
        transition: .3s;
    }
    .site-navbar ul li {
        width: 100%;
        text-align: center;
    }
    .site-navbar ul li a {
        padding: 8px;
    }
    .site-navbar ul li a:hover {
        background-color: rgba(255, 255, 255, .1);
    }
    .site-navbar ul.open {
        max-height: 100vh;
        overflow: visible;
        z-index: 9;
    }
    .intro-area h2 {
        font-size: 36px;
        margin-bottom: 15px;
    }
    .ads-blog {
        display: none;
        /* navbar css for mobile end */
    }
    .site-navbar ul.open .zeynep-overlay-1 {
        display: block;
    }
    .zeynep-overlay-1 {
        background-color: rgba(0, 0, 0, .42);
        bottom: 0;
        display: none;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9;
    }
    .zeynep-overlay-1-block {
        display: block !important;
    }
    .mob-po-relative {
        position: relative;
    }
    .h_50 {
        height: 50px;
    }
    /* .counsellor_details .uni-item-list .item-abouttxt img {
        max-width: fit-content;
        position: absolute;
        display: inline-flex;
        text-align: center;
        top: -50%;
        margin-left: 6%;
    } */
    .unifilter-page.counsellor_details {
        margin-top: 180px;
    }
    .mob-mt-160 {
        margin-top: 160px;
    }
    .country-detail-mob {
        margin-top: 14px;
        padding-bottom: 20px !important;
    }
    .country-detail-mob img {
        height: 100% !important;
    }
    .country-detail-mob span {
        width: 32%;
        text-align: left;
    }
    .react-responsive-modal-modal {
        margin: 0.2rem !important;
    }
    /* for university courses search */
    .searchuniversitiesandcourses {
        padding: 0px !important;
        width: 100% !important;
        top: 100% !important;
    }
    .mob-mt-0 {
        margin-top: 0px;
    }
    changes in style2.css .course__ {
        left: 0;
    }
    .course__font {
        font-size: 11px;
        /* margin-top: 40px; */
    }
    /* .detailstudy table tr td img {
        padding: 0 4px;
        position: absolute;
        top: 0;
    } */
    .appli-st {
        text-align: left !important;
        margin-top: 20px; 
    }
    .track-appli {
        text-align: left;
    }
    .appli-box-d {
        padding: 10px 30px;
    }
    .text-right {
        text-align: left!important;
    }
    .other_info {
        padding: 25px;
    }
    .couns-d {
        padding: 10px 12px;
    }
    .shortlist-about h1 {
        font-size: 15px;
    }
    .short-list-box {
        display: block;   
     height: 270px;
    }
    .counsellor-btn {
        margin-top: 8px;
    }
    .appli-btn {
        top: 225px;
        left: 2px;
    }
    
    .shortlist-appli-btn {
        font-size: 12px;
        padding: 5px 0;
        width: 90px;
    }
    .get-counsellor-btn {
        padding: 9px 32px;
    }
    .counsellor-btn {
        margin-top: 8px;
    }
    .shortlist-about {
        padding: 12px;
    }
    
    .short-list-uni img {
        max-height: 78px;
        margin: 10px 0px 0px 6px;
    }
    .service-heading {
        position: absolute;
        top: -38px;
        font-size: 18px;
    }
    .text-right {
        text-align: left!important;
    }
    
}


/* mobile breakpoint end */


/*media query for large devices*/

@media screen and (min-width: 1600px) {
    /* .short-list-uni {
        width: 25%;
    } */
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .user_nav ul li a img {
        margin-right: 12px !important;
        width: 36px !important;
    }
    .short-list-uni img {
        width: 62% !important;
        margin: 10px !important;
    }
    .tab_offer {
        top: 0px;
    }
    .appoint {
        width: 100%;
    }
    .other_info:before {
        height: 71px;
    }
    .first-con1 {
        padding: 10px 0px;
    }
    .shortlist-appli-btn {
        padding: 7px 0;
        width: 138px;
        margin: 4px 9px;
    }
    .get-counsellor-btn {
        padding: 13px 26px;
    }
    .short-list-uni {
        width: 131px;
    }
    .appoint h1 {
        font-size: 14px;
    }
    .service-heading {
        font-size: 18px;
    }
}