html {
    width: 100%;
    /* overflow-x: hidden; */
    /* Hide horizontal scrollbar */
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Montserrat", sans-serif !important;
}

.mobile_css h1,
.mobile_css h2,
.mobile_css h3,
.mobile_css h4,
.mobile_css h5,
.mobile_css h6,
.mobile_css p,
.mobile_css li {
    font-family: "Montserrat", sans-serif !important;
    line-height: 1.2;
    margin: 0;
    color: #555;
}

.mainsheading {
    margin: 20px auto;
    width: 100%;
}

.titlewithline {
    color: #555;
    position: relative;
}

.titlewithline:after,
.titlewithline:before {
    position: absolute;
    content: "";
    margin: 0 auto;
    width: 100px;
    height: 2px;
    background-color: #fbb415;
    bottom: 22px;
}

.titlewithline:before {
    right: 180px;
    left: 0px;
}

.titlewithline:after {
    left: 180px;
    right: 0px;
}

.titlewithline span {
    display: block;
    padding: 7px;
}

.titlewithline span svg {
    width: 30px;
    padding-top: 7px;
    vertical-align: middle;
    margin-right: 3px;
}

.about-cont {
    padding: 20px;
    text-align: center;
}

.about-cont p {
    font-size: 15px;
    line-height: 30px;
    color: #555;
}

/* terms&conditon,privacy css */
.terms-content {
    padding: 0px 5px;
}

.terms-cont p {
    padding: 5px;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 5px;
    text-align: left;
}

.terms-list {
    list-style-type: none;
    padding-left: 0px;
    text-align: left;
}

.terms-list li {
    line-height: 25px;
}

.terms-list li:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #127a7d;
    border-radius: 50%;
    left: 0;
    bottom: 0;
    display: inline-block;
    margin: 0 10px;
}

.order-privacy1 {
    color: #127a7d;
    padding: 10px;
    font-weight: 600;
}

.service-funding1 {
    width: 30px;
}

.ser-img img {
    width: 90%;
}

.accom-bx {
    margin: 10px 0px;
}

.accom-head1 {
    font-size: 16px;
}

.accom-head1::after {
    content: "";
    width: 60px;
    height: 2px;
    background: #fcbd30;
    display: block;
    margin-bottom: 10px;
    margin-top: 5px;
}

.accom-bx p {
    padding: 5px;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 5px;
    text-align: left;
}

.mainsheading {
    margin: 20px auto;
    width: 100%;
}

.headingwithline {
    color: #fbb415 !important;
    font-weight: 600;
    position: relative;
}

.headingwithline:after,
.headingwithline:before {
    position: absolute;
    content: "";
    margin: 0 auto;
    width: 100px;
    height: 2px;
    background-color: #fbb415;
    bottom: 22px;
}

.headingwithline:before {
    right: 180px;
    left: 0px;
}

.headingwithline:after {
    left: 180px;
    right: 0px;
}

.headingwithline span {
    display: block;
    padding: 7px;
}

.headingwithline span svg {
    width: 30px;
    padding-top: 7px;
    vertical-align: middle;
    margin-right: 3px;
}

.headingwithline span img {
    width: 30px;
    padding-top: 7px;
    vertical-align: middle;
    margin-right: 3px;
}

.about-content-info {
    box-shadow: 0 0 6px #ccc;
    padding: 20px;
    text-align: center;
}

.about-content-info p {
    font-size: 15px;
    line-height: 30px;
    color: #555;
}

.ab-exclusives {
    height: 220px !important;
    background: #fff;
    -webkit-box-shadow: 0 5px 10px 0 #b8e2fe;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
}

.ab-exclusives img {
    padding-top: 10px;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.ab-exclusives p {
    padding: 15px 20px;
    color: #555;
}

.aco-para p {
    font-size: 15px;
    text-align: center;
    line-height: 25px;
    padding: 0 15px;
    color: #555;
}

.bluedark-co {
    background-color: #e5f1f1;
}

.postcard-img {
    flex-wrap: wrap;
    display: flex;
    border-radius: 10px;
    margin: 0;
    overflow: hidden;
    position: relative;
    align-items: center;
}

.postcard-img img {
    margin: 5% 5% 5% auto !important;
    width: 60% !important;
}

.coach-list {
    padding: 15px;
    list-style: none;
}

.coach-list li {
    line-height: 36px;

}

.coach-list li:before {
    content: "";
    width: 8px;
    height: 8px;
    color: #555;
    background-color: #116871;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
}

.mainsheading p {
    font-size: .8rem;
    color: #555;
}

.sub-section-ser {
    padding: 4px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0 !important;
}

.why_slide_ser {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.why-choose-add {
    padding: 0 0 20px;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    margin: 15px 0;
    width: 100%;
}

.why-choose-add a {
    outline: none;
    text-decoration: none;
    color: inherit;
    background-color: transparent;
}

.scale {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.why-choose-add .content-box-fig {
    padding: 0 10px 20px;
    text-align: left !important;
}

.content-box-fig h5 {
    line-height: 1.2;
    margin: 0;
    color: #333;
}

.why-choose-add .content-box-fig p {
    padding-right: 0;
    text-align: -webkit-left;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: .8rem;
    color: rgb(109, 109, 109);
    padding-right: 0;
    text-align: -webkit-left;
}

.read-btn {
    font-size: 14px;
    border-radius: 5px;
    padding: 8px 25px;
    color: #fff !important;
    background: linear-gradient(90deg, #0e2846, #127b7e);
    z-index: 0;
    transition: all .3s ease 0s;
    font-weight: 500;
    display: inline-block;
}

.main-terms-s p {
    padding: 5px 10px;
    font-size: 15px;
    line-height: 30px;
    color: #555;
}

.condition-list-sec li:before {
    font-family: "FontAwesome";
    width: 10px;
    height: 10px;
    color: #127a7d;
    border-radius: 50%;
    left: 0;
    bottom: 0;
    display: inline-block;
    margin: 0 15px;
    content: "\f0a4";
    font-weight: 500;
}

.condition-list-sec {
    list-style: none;
    padding: 0;
}

.condition-list-sec li {
    color: #555;
}

.postcard-sec .postcard-sec-text {
    padding: 15px 0;
}

.postcard-sec .postcard-sec-text h4 {
    font-size: 18px;
    padding: 0 !important;
    color: #333;
}

.postcard-sec .postcard-sec-text h4:after {
    content: "";
    width: 60px;
    height: 2px;
    background: #fcbd30;
    display: block;
    margin-bottom: 10px;
    margin-top: 6px;
}

.postcard-sec-img {
    width: 100% !important;
}

.postcard-para-txt {
    font-size: 15px;
    align-items: inherit;
    line-height: 24px;
    display: inline-flex;
    vertical-align: middle;
    color: #555;
}

.bg-grey {
    background-color: #d6d4d2;
}

.appli-c {
    font-size: 12px;
}

.apply-box {
    box-shadow: 0 0 10px 1px #ddd;
    margin: 20px 0;
    padding: 20px 10px;
    background-color: rgb(255, 255, 255);
}

.apply-head:after {
    content: "";
    border-bottom: 4px solid #fbb415;
    width: 15%;
    margin-top: 8px;
    display: block;
}

.fields-wrap form .field {
    height: 50px;
    width: 100%;
    margin-top: 20px;
    position: relative;
}

.fields-wrap form .field input:focus,
.fields-wrap form .field input:valid {
    border-color: #fbb415;
}

.fields-wrap form .field input,
.fields-wrap form .field select {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 15px;
    padding-left: 50px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    transition: all .3s ease;
    color: #555;
}

.fields-wrap form .field input:focus~label,
.fields-wrap form .field input:valid~label {
    top: 0;
    left: 20px;
    font-size: 12px;
    padding: 0 3px;
    color: #fbb415;
    background: #fff;
    transform: translateY(-50%);
}

.fields-wrap form .field label {
    position: absolute;
    font-weight: 400;
    pointer-events: none;
    transition: all .3s ease;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.fields-wrap form .field input:focus~span,
.fields-wrap form .field input:valid~span {
    color: #fbb415;
}

.fields-wrap form .field span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 18px;
    color: #555;
}

.note-text {
    color: red;
    font-size: 14px;
}

.note-text1 {
    color: #555;
    font-size: 14px;
}

.sel_file {
    border-radius: 5px;
    border: 1px solid #fbb415 !important;
}

.sel_file input[type=file] {
    cursor: pointer;
    margin-top: 10px;
    border-color: transparent !important;
}

.ques-msg {
    width: 100%;
    border-color: #fbb415 !important;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 15px;
    font-family: inherit;
    color: #555;
}

.bggreadent-n {
    margin-right: 0;
    background: linear-gradient(77deg, #047475, #01132f);
    color: #fff;
    border: none;
    padding: 10px 25px;
    transition: all .3s ease;
    border-radius: 5px;
}

.shade-c1 {
    box-shadow: 0 0 6px #ccc;
    margin-bottom: 30px;
}

.add-details {
    padding: 10px 0;
}

.add-details h1 {
    font-size: 16px;
    color: #fcbd30;
}

.add-details p {
    font-size: 15px;
    margin-bottom: 8px;
}

.add-details a {
    text-decoration: none;
    font-size: 15px;
    color: #555;
}

.c-infor {
    padding-bottom: 10px;
}

.c-tag {
    color: #fcbd30;
    font-size: 16px;
}

.get-touch:after {
    content: "";
    border-bottom: 4px solid #fbb415;
    width: 15%;
    margin-top: 5px;
    display: block;
}

.c-select {
    border-color: #fbb415 !important;
}

.fields-wrap form .field label {
    position: absolute;
    top: 50%;
    left: 50px;
    color: #999;
    font-weight: 400;
    font-size: 16px;
    pointer-events: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .3s ease;
}

.ques-msg1 {
    border-color: #d3d3d3;
}

.fbtn-mt-100 {
    margin-top: 100px;
}

.bg-grey-pa {
    padding-top: 20px;
    padding-bottom: 10px;
}

.fields-wrap form .field select.form-control {
    line-height: 24px;
    display: block;
    padding: 0px 40px 1px 52px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAANCAYAAABGkiVgAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+V1MENgyAUBmBeIuc6QkfACZpe2KObdYPe4YAjdARH8A4JfZBiUBQenozhffzyXgBjzOic+zDGhPf+KaX84jv5OasHpdQMAI+/svbAAbTWGqwXqX4Yhgm01it+uGXRSPAJGAkMNYWkAneae+AK+MLje0PQe+AWGLyIUmEKuENbMOd8OTQlnWH85XxctqSExEvW5UuwSNqAd7OLXS4SpgVFUgpcAy+T1uAW2ESPzaOAJDQswlG6Y+dH6r3wA0MIuckCljcTAAAAAElFTkSuQmCC) no-repeat right 10px top 50%;
    background-size: 15px;
    appearance: none;
}

/* faq-page-css */
.bg-light-g {
    background-color: #f6f5f4;
}

.faqpage-web .faqleft-web {
    min-height: auto;
    padding: 15px;
    background-size: 115px !important;
    background: url(../img/faq-bg.7cab9fba.png) 0 0 no-repeat;
}

.faqpage-web .faqleft-web h1 {
    font-size: 22px;
    font-weight: 600;
}

.faqpage-web .faqleft-web .heading h1 span {
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid #333;
    margin-bottom: 20px;
}

.faqpage-web .faqleft-web .heading p {
    font-size: 13px;
    margin-right: 0;
    margin-top: 5px;
    line-height: 22px;
}

.faqtabs-web {
    display: block;
    padding: 0;
    max-width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
}

.faqtabs-web .nav-tabs-web {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 0;
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 13px 0;
}

.nav-tabs-web {
    list-style: none;
}

.faqtabs-web .nav-tabs-web .nav-item {
    width: auto;
    margin-right: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.faqtabs-web .nav-tabs-web .nav-link {
    background-color: #eee;
    border-radius: 5px;
    border: 0;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    border-radius: 0;
    color: #555;
}

.faqtabs-web .nav-tabs-web .nav-link.active {
    text-align: left;
    box-shadow: 0 0 12px #a5a5a5;
}

.faqtabs-web .nav-tabs-web .nav-link.active {
    background-color: #fcbd30;
    box-shadow: 0 5px 3px #d3d3d3;
    font-weight: 500;
}

.acc-faq {
    background: linear-gradient(77deg, #047475, #01132f);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
    color: #fff !important;
    box-shadow: 0 0 20px 0 hsl(0deg 0% 84% / 50%) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.acc-item-faq {
    margin-bottom: 20px;
}

.acc-content {
    font-size: 15px;
    line-height: 30px;
    color: #555;
}

.acc-faq::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(../img/awesome-plus.svg) !important;
    background-repeat: no-repeat;
    background-size: 16px !important;
    transition: transform .2s ease-in-out;
}

.acc-faq[aria-expanded='true']::after {
    background-image: url(../img/awesome-minus.svg) !important;
    transform: rotate(0deg) !important;
    margin-top: 10px;
}

.acc-faq:not(.collapsed)::after {
    transform: rotate(0deg) !important;
}

/* online-coaching-css */
.bluedark-c {
    background-color: #e5f1f1;
}

.postcard-c {
    flex-wrap: wrap;
    display: flex;
    border-radius: 10px;
    margin: 0;
    overflow: hidden;
    position: relative;
    align-items: center;
}

.postcard-c .postcard__img-c {
    margin-bottom: 0;
    width: 100%;
    position: relative;
    margin: 30px 0;
}

.postcard-c .postcard__text-c {
    padding: 15px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.postcard-c .postcard__text-c h4 {
    font-size: 16px;
    padding-left: 35px;
}

.postcard__preview-txt-c {
    align-items: inherit;
    line-height: 22px;
    display: inline-flex;
    vertical-align: middle;
}

.postcard__preview-txt-c>img {
    max-height: 55px;
    float: left;
    margin-right: 15px;
}

.postcard__preview-txt-c .p {
    font-size: 15px;
}

/* packages-css */
.packagelist .item-package .card {
    border-color: #047475;
    border-radius: 40px 0;
    margin: 0 20px;
    position: relative;
    box-shadow: 0 0 10px #ccc;
}

.packagelist .item-package .card .card-header {
    padding: 25px 15px;
    background-color: #047475;
    border-radius: 40px 0 0 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.packagelist .item-package .card .card-header h5 {
    font-size: 16px;
    color: #fff;
}

.packagelist .item-package {
    margin: 30px 0;
}

.packagelist .item-package .card .card-body {
    max-height: 370px;
    overflow-y: auto;
    margin-bottom: 8px;
}

.packagelist .item-package .card .card-body ul li {
    font-size: 13px;
    border-bottom: 1px dotted #047475;
    padding: 9px 0;
}

.packagelist .item-package .card .pricebox {
    width: 69px;
    height: 69px;
    border-radius: 50%;
    border: 1px solid #fcbd30;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -43px;
    background: #fff;
    left: 0;
    right: 0;
    flex-wrap: wrap;
    margin: 0 auto;
}

.packagelist .item-package .card .card-body ul {
    list-style: none;
}

.packagelist .item-package:nth-child(2) .card .card-header {
    background-color: #fcbd30;
}

.packagelist .item-package:nth-child(2) .card {
    border-color: #fcbd30;
}

.main-pak-s p {
    padding: 5px 10px;
    font-size: 15px;
    line-height: 25px;
    color: #555;
}

.red-mor {
    text-decoration: none;
}

.border-pack {
    width: 80%;
    margin: auto;
}

.pack-btn a:hover {
    color: #fff;
}

.shareblog1 ul li {
    display: inline-block;
    margin: 0 0 0 2px;
}

.shareblog1 {
    margin-top: 25px;
}

.shareblog1 ul {
    padding-left: 15px;
}

.sec-text-mar {
    padding: 0px !important;
    padding-top: 8px !important;
}

.loadingPage {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    /* background-color: #00000087; */
    background-color: white;
}

/* mobile acc css */
.mobile_acc .accordion-flush .accordion-item:first-child {
    border-top: 0;
}

.mobile_acc .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.mobile_acc .accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.mobile_acc .accordion-header {
    margin-bottom: 0;
}

.mobile_acc .accordion-flush .accordion-collapse {
    border-width: 0;
}

.mobile_acc .collapse:not(.show) {
    display: none;
}

.mobile_acc .accordion-body {
    padding: 1rem 1.25rem;
}

.mobile_acc .acc-content {
    font-size: 15px;
    line-height: 30px;
    color: #555;
}

.mobile_acc .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.mobile_acc .acc-faq {
    background: linear-gradient(77deg, #047475, #01132f);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
    color: #fff !important;
    box-shadow: 0 0 20px 0 hsl(0deg 0% 84% / 50%) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.mobile_acc .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
}