.notification-container {
  background: #00000096;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100vh;
  position: fixed;
  z-index: 9999;
}
.btn-clear {
  background: #fbb415;
  color: "#fff";
  border-radius: 5px !important;
}

.btn-clear:hover {
  background: #f2c565;
}

.active-notificationSideBar {
  position: fixed;

  bottom: 0px;
  right: 0px;
  width: 400px;
  border-top-left-radius: 5px;
  height: 94vh;
  overflow-y: scroll;
  z-index: 10000;
  padding: 20px;
  background: #efefef;
  transition: right 0.5s ease-in-out;
}

.active-notificationSideBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 0px;
  right: 0px;
  width: 450px;
  border-top-left-radius: 5px;
  height: 92.5vh;
  z-index: 10000;
  padding: 20px;
  background: linear-gradient(to bottom, #047374, #0a2945);
  transition: right 0.5s ease-in-out;
}

@media screen and (max-width: 600px) {
  .active-notificationSideBar {
    width: 100%;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.notification-card {
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  color: black;
  cursor: pointer;
  text-underline-offset: none;
  text-decoration: none;
}

.notification-card-read {
  background: transparent;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px;
  text-underline-offset: none;
  border: 1px solid #b3b3b3;
  color: #cdcbcb;
}

h6 {
}

.active-notificationSideBar.show {
  right: 0;
}

.active-notificationSideBar.hide {
  right: -400px;
}
