@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap);
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/style2.css";

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  content: unset;
}

.breadcrumb-item {
  font-weight: 600;
  font-size: 14px;
}


.breadcrumb-item.active {
  color: #fcbd30 !important;
}

.editor_css li {
  white-space: unset !important;
}

.editor_css table tr td {
  padding: 20px;
}

.editor_css table {
  margin-top: 10px;
}

.border-card {
  border: 1px solid black;
  border-radius: 30px;
}

.popup-overlay {
  display: none;
}

.inner-html {
  margin-top: "5px";
  margin-bottom: "5px";
  color: "red";
}

/* media query  */

@media (max-width: 600px) {
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    align-self: "center";
    justify-content: center;
    animation: fadeIn 0.5s;
    width: "100%";
    z-index: 999999;
    /* margin-left: 20%; */
    /* border: '2px solid red'; */
  }

  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 300;
    /* max-width: 240px; */
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    /* top:40%;  */
    margin: auto;
    margin-bottom: 10;
    animation: slideUp 0.5s ease-out;
    font-family: "Poppins", sans-serif;
    /* margin: 11px; */
    animation: slideUp 0.5s ease-out;
    background-color: white;
  }

  .popup-content .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #000;
  }

  .popup-content .close-icon:hover {
    color: #dc3545;
  }

  .popup-content h2 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-content button {
    padding: 9px 19px;
    margin: 10px;
    border: none;
    border-radius: 50px;

    color: #fff;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #127b7e;
  }

  .browsing-option-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .browsing-option-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo img {
    width: 100%;
  }

  .logo {
    /* width: 40%; */
    height: 25px;
    border-radius: 4px;
    /* border: 1px solid #d9d0d0; */
    padding: 4px;
  }

  .browserImg img {
    width: 100%;
    height: 25px;
    border-radius: 4px;
    /* border: 1px solid #d9d0d0; */
    padding: 4px;
  }

  button.browser {
    color: #127b7e;
    border: 1px solid;
    background: transparent;
    font-weight: 500;
  }
  .custom-input .ant-input::placeholder {
    color: "white"; /* Red color for placeholder text */
    opacity: 1; /* Ensure the color is visible */
  }

  .university-search-input::placeholder {
    color: "white";
  }
  .ant-input
    css-dev-only-do-not-override-1c0na6j
    ant-input-outlined
    ant-select-selection-search-input
    university-search-input {
    color: "white";
  }

  .browsing-options-1 p {
    font-size: 15px;
  }

  .browsing-options-2 p {
    font-size: 15px;
  }

  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }
}
