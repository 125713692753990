[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none;
}

.react-calendar {
  width: 400px !important;
  max-width: 100% !important;
  background-color: #fff !important;
  color: #222 !important;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.125em !important;
  border: 0 !important;
  margin-left: 20px;
  margin-top: 20px;
}

.react-calendar__navigation button {
  color: #fab418;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #fab418;
  color: white;
}

.app-container {
  border-radius: 4px;
  overflow: hidden;
  width: 720px;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
}

.buttons-container {
  position: absolute;
  bottom: 15px;
  right: 45px;
  height: 40px;
  font-family: "Roboto", sans-serif;
}

.cancel-button,
.save-button {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  border-radius: 2px;
  margin-right: 15px;
  cursor: pointer;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.cancel-button {
  background: transparent;
  color: #fab418;
  border: 1px solid #fab418;
  margin-top: 6px;
}


/* Datepicker Stuff */
.datepicker {
  position: relative;
  width: 100%;
  display: block;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease;
}

.datepicker.am {
  background: white;
}

.datepicker.pm {
  background: #0DAD83;
}

.datepicker-header {
  width: 100%;
  color: white;
  overflow: hidden;
}

.datepicker-title {
  width: 50%;
  float: left;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  text-align: left;
  font-size: 20px;
}

.datepicker-subheader {
  width: 50%;
  float: left;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  padding: 0 15px;
  text-align: right;
}

.datepicker-calendar {
  width: 70%;
  float: left;
  padding: 20px 15px 15px;
  max-height: 400px;
  display: block;
}

.calendar-header {
  color: black;
  font-weight: bolder;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  position: relative;
}

.current-month-container {
  display: inline-block;
  height: 30px;
  position: relative;
}

.goback,
.goforward {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: -4px;
}

.goback path,
.goforward path {
  -webkit-transition: stroke 0.15s ease;
  transition: stroke 0.15s ease;
}

.goback {
  float: left;
  margin-left: 3.8%;
}

.goforward {
  float: right;
  margin-right: 3.8%;
}

.calendar-day-header {
  width: 100%;
  position: relative;
}

.day-label {
  color: #216a69;
  padding: 5px 0;
  width: 14.2857142%;
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

.datecontainer {
  width: 14.2857142%;
  display: inline-block;
  text-align: center;
  padding: 4px 0;
}

.datenumber {
  max-width: 35px;
  max-height: 35px;
  line-height: 35px;
  margin: 0 auto;
  color: #000;
  position: relative;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
  transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
}

.no-hover .datenumber,
.no-hover .datenumber:hover,
.no-hover .datenumber:before,
.no-hover .datenumber:hover::before {
  cursor: default;
  color: #8A8A8A;
  background: transparent;
  opacity: 0.5;
}

.no-hover .datenumber.day-selected {
  color: white;
}

.datenumber:hover {
  color: white;
}

.datenumber:before {
  content: '';
  display: block;
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  z-index: -1;
  background: transparent;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
  transition: all 0.25s cubic-bezier(0.7, -0.12, 0.2, 1.12);
  -webkit-transition-property: background, color, border, -webkit-transform;
  transition-property: background, color, border, -webkit-transform;
  transition-property: background, transform, color, border;
  transition-property: background, transform, color, border, -webkit-transform;
}

.datenumber:hover::before {
  background: #fab418;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.day-selected {
  color: white;
}

.datenumber.day-selected:before {
  background: #fab418;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: select-date .25s forwards;
  animation: select-date .25s forwards;
}

@-webkit-keyframes select-date {
  0% {
    background: #fab418;
  }

  100% {
    background: #fab418;
  }
}

@keyframes select-date {
  0% {
    background: #fab418;
  }

  100% {
    background: #fab418;
  }
}

/* timepicker styles */
.timepicker-container-outer { 
  width: 100%;
  height: 35vh;
  overflow: auto; 
  -webkit-tap-highlight-color: transparent;
  transition: background 0.15s ease;
  border-left: 2px solid #ddd;
  margin: 15px 0px;
}

.timepicker-container-inner {
  width: 100%;
  height: 100%;
  max-width: 320px;
  margin: 0 auto;
  position: relative;
  display: block;
}

.timeline-container {
  display: block;
  float: left;
  position: relative;
  width: 100%;
}

.current-time {
  display: block;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  top: -25px;
  left: -20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.current-time::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fab418;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 20px 20px 3px 20px;
  z-index: -1;
  top: 0;
}

.actual-time {
  color: white;
  line-height: 40px;
  font-size: 12px;
  text-align: center;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.timeline {
  display: block;
  z-index: 1;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
}


.hours-container {
  display: block;
  z-index: 0;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 31px;
  left: 1px;
}

.hour-mark {
  width: 2px;
  display: block;
  float: left;
  height: 4px;
  background: #0DAD83;
  position: relative;
  margin-left: calc((100% / 12) - 2px);
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease;
}

.hour-mark:nth-child(3n) {
  height: 6px;
  top: -1px;
}

.display-time {
  width: 100%;
  display: inherit;
  margin-top: 15px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  float: left;
  position: relative;
  font-size: 16px;
  text-align: center;
  -webkit-transition: color 0.15s ease;
  transition: color 0.15s ease;
}

.calen-head {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 0px;
}

.datepicker-calendar:after {
  height: 10px;
  display: block;

  border-right: 1px #000;
  content: '';
}

.decrement-time,
.increment-time {
  cursor: pointer;
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  top: 6px;
  font-size: 20px;
}

.decrement-time {
  left: 0;
  text-align: left;
}

.increment-time {
  right: 0;
  text-align: right;
}

.increment-time path,
.decrement-time path {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.time__ {
  /* width: calc(100% - 48px);
  position: relative;
  left: 24px; */
  height: 36px;
}

.time__:after {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: white;
  left: 0;
  right: 0;
  opacity: 0.5;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.time.time-active:after {
  display: none;
}

.time-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 34px;
  line-height: 34px;
  bottom: 2px;
  width: 100%;
  border: none;
  background: none;
  text-align: center;
  color: white;
  font-size: inherit;
  opacity: 0;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  cursor: pointer;
}

.time-input:focus, .time-input:active {
  outline: none;
}

.formatted-time {
  cursor: pointer;
}

.time-input:focus {
  cursor: auto;
}

.time-input:focus~.formatted-time {
  border-radius: 2px;
  background: #fab418;
  color: white;
  cursor: default;
  border-radius: 10px;
}

.am-pm-container {
  width: 40%;
  padding-left: 15px;
  float: right;
  height: 36px;
  line-height: 36px;
  display: block;
  position: relative;
  margin-top: 30px;
}

.am-pm-button {
  width: calc(50% - 5px);
  height: 36px;
  line-height: 36px;
  background: #2196F3;
  text-align: center;
  color: white;
  border-radius: 4px;
  float: left;
  cursor: pointer;
}

.am-pm-button:first-child {
  background: #0DAD83;
  color: white;
}

.am-pm-button:last-child {
  background: white;
  color: #0DAD83;
  margin-left: 10px;
}

@-webkit-keyframes select-date-pm {
  0% {
    background: rgba(255, 255, 255, 0.5);
  }

  100% {
    background: #FFF;
  }
}

@keyframes select-date-pm {
  0% {
    background: rgba(255, 255, 255, 0.5);
  }

  100% {
    background: #FFF;
  }
}

.datepicker.am .datepicker-header {
  color: white;
  background: #216a69;
}

.datepicker.am .current-time::after {
  background: #216a69;
}

.datepicker.am .actual-time {
  color: white;
}

.datepicker.am .display-time {
  color: #000;
  font-weight: 500;
}

.time__ {
  width: 50%;
  margin: auto;
}

.time-active {
  width: 50%;
  margin: auto;
}

.datepicker.am .time-input {
  color: #216a69;
}

/* .datepicker.am .time:after {
  background: #216a69;
} */

.datepicker.am .increment-time path,
.datepicker.am .decrement-time path {
  stroke: #216a69;
}

.datepicker.pm .datepicker-header {
  background: white;
  color: #FF693C;
}

.datepicker.pm .datepicker-subheader {
  color: #0DAD83;
}

.datepicker.pm .goback:before,
.datepicker.pm .goback:after,
.datepicker.pm .goforward:before,
.datepicker.pm .goforward:after {
  background: white;
}

.datepicker.pm .day-label {
  color: white;
}

.datepicker.pm .datenumber {
  color: white;
}

.datepicker.pm .datenumber:hover::before {
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.datepicker.pm .datenumber.day-selected {
  color: #FF693C;
}

.datepicker.pm .datenumber.day-selected:before {
  background: white;
  -webkit-animation: select-date-pm .25s forwards;
  animation: select-date-pm .25s forwards;
}

.datepicker.pm .current-month-container {
  color: white;
}

.datepicker.pm .current-time::after {
  background: white;
}

.datepicker.pm .actual-time {
  color: #fab418;
}

.datepicker.pm .display-time {
  color: white;
}

.datepicker.pm .timeline::before, .datepicker.pm .pm .timeline::after {
  background: white;
}

.datepicker.pm .hour-mark {
  background: white;
}

.datepicker.pm .am-pm-button:last-child {
  color: #fab418;
}

.datepicker.pm .cancel-button {
  background: none;
  color: white;
}

.datepicker.pm .save-button {
  background: white;
  color: #FF693C;
}

.datepicker.pm .goback path,
.datepicker.pm .goforward path {
  stroke: white;
}

.datepicker.pm .time-input:focus~.formatted-time {
  background: white;
  color: #FF693C;
}

.datepicker.compact .datepicker-title,
.datepicker.compact .datepicker-subheader {
  width: 100%;
  text-align: center;
}

.datepicker.compact .datepicker-title {
  height: 50px;
  line-height: 50px;
}

.datepicker.compact .datepicker-subheader {
  height: 30px;
  line-height: 30px;
}

.datepicker.compact .display-time {
  width: 60%;
  font-size: 20px;
  line-height: 36px;
}

.datepicker.compact .app-container {
  width: 100%;
}

.datepicker.compact .datepicker-calendar {
  width: 100%;
  margin: 0 auto;
  float: none;
}

.datepicker.compact .timepicker-container-outer {
  width: 81%;
  margin: 0 auto;
  float: none;
  top: -15px;
}

.datepicker.compact .buttons-container {
  position: relative;
  float: right;
}